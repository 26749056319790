<template>
  
  <kinesis-container tag="div" class="full">

    <!-- Inset shadow overlay -->
    <div class="pointer-events-none absolute w-screen h-screen top-0 z-10" style="box-shadow: inset 0 0 10rem 1rem #fff;"></div>

    <div v-if="projects" id="artwork" class="w-screen h-screen transition duration-500">
      <transition appear name="fade">
        <svg width="100%" height="100%" viewBox="0 0 1440 1024" preserveAspectRatio="xMidYMid slice" overflow="visible" id="artsvg">
          
          <!-- Map Artwork -->
          <kinesis-element tag="g" :strength="1">
            <image id="image" href="@/assets/landing-map.svg" width="100%" height="100%" class="transition duration-500" :opacity="selectedProject ? .5 : 1" />
          </kinesis-element>
          
          <!-- Project Dots -->
          <kinesis-element tag="g" :strength="3">
            <g 
            v-for="project in projects"
            :key="project.id" 
            class="group cursor-pointer transition"
            :transform="`translate(${project.data.dot_x},${project.data.dot_y})`"
            @click="$store.commit('selectProject', project.id)">
              <circle 
              :r="relativeToScale(projectDotSize)" 
              :class="{ 'scale-0' : selectedProject && selectedProject != project.id }" 
              class="fill-yellow-400 stroke-yellow-400/40 stroke-[8px] transition group-hover:scale-150 duration-500" />
              <image 
              :href="project.data.icon.url" 
              :x="relativeToScale(-projectDotSize)" :y="relativeToScale(-projectDotSize)" 
              :width="relativeToScale(projectDotSize*2)"
              :class="{ 'scale-0' : selectedProject && selectedProject != project.id }" 
              class="transition group-hover:scale-125 duration-500 delay-100" />
            </g>
          </kinesis-element>

          <!-- Event Dots -->
          <kinesis-element tag="g" :strength="3">
            <g 
            v-for="event in events" 
            :key="event.id" 
            class="group cursor-pointer" 
            :transform="`translate(${event.data.dot_x},${event.data.dot_y})`"
            @click="$store.commit('selectEvent', event.id)" >
              <g :class="[event.data.project.id == selectedProject ? 'scale-100 visible' : 'scale-0 hidden']" class="transition duration-500">
                <!-- Ping effect dot -->
                <circle 
                :r="relativeToScale(eventDotSize+5)"
                class="animate-ping fill-white/100" />
                <!-- Main dot -->
                <circle 
                :r="relativeToScale(eventDotSize)"
                :class="event.id == selectedEvent ? 'fill-black stroke-black/40' : 'fill-yellow-400 stroke-yellow-400/40'"
                class="stroke-[8px] group-hover:scale-150 transition duration-500" />
              </g>
            </g>
          </kinesis-element>

        </svg>
      </transition>
    </div>
  </kinesis-container>

</template>

<script>
import Panzoom from '@panzoom/panzoom' // Docs at https://github.com/timmywil/panzoom
import { KinesisContainer, KinesisElement} from 'vue-kinesis' // Docs at https://aminerman.com/kinesis/#/

export default {
    name: 'Artwork',
     data(){
      return {
        panzoom: null,
        currentScale: 2,
        projectDotSize: 20, // in pixels
        eventDotSize: 10 // in pixels
      }
    },
    components: { KinesisContainer, KinesisElement },
    computed: {
      projects () {
        return this.$store.state.projects
      },
      selectedProject () {
        return this.$store.state.selectedProject
      },
      events () {
        return this.$store.state.events
      },
      selectedEvent () {
        return this.$store.state.selectedEvent
      },
      
    },
    mounted() {
      const art = document.getElementById('artwork')

      // Bind panzoom to the element and set initial parameters
      this.panzoom = Panzoom(art, {
        maxScale: 5,
        minScale: 1,
        duration: 1500,
        startScale: this.currentScale,
        startX: 0, // 0 is default
        startY: 0, // 0 is default
        step: .25,
      })
  
      art.addEventListener('wheel', this.panzoom.zoomWithWheel)
      art.addEventListener('panzoomchange', (evt) => {
        this.currentScale = evt.detail.scale
      })
      setTimeout(() => this.panzoom.zoom(1.2,{ animate:true }),200)
      // this.panzoom.setOptions({ contain: 'outside' })

    },
    methods: {
      test () {
        console.log('test')
      },
      relativeToScale(val){
        if (this.currentScale > 1) {
          return val / this.currentScale
        } else {
          return val
        }
      },
      selectedProjectClass(project){
        if(this.selectedProject){
          return project.id == this.selectedProject ? null : 'opacity-0'
        }
      },
    }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: all 2s ease-in-out;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>