<template>
    <div class="absolute bottom-0" v-if="selectedProjectData">

        <div v-if="!selectedEventData && selectedProjectData" class="items-center place-content-end px-8 py-6 border border-black mb-10 bg-white shadow-lg text-center transform-all opacity-0 animate-moveUp">
            <a href="#" @click="$store.commit('selectProject', null)" style="position: absolute; top: -.87rem; right: -.8rem; z-index:10">
                <ion-icon name="close" class="bg-yellow-400 text-black border-black border rounded-full w-5 h-5 p-0.5 hover:bg-black hover:text-white"></ion-icon>
            </a>
            <h3 class="text-2xl font-serif font-bold italic leading-none tracking-tight mb-3">{{ selectedProjectData.data.title[0].text }}</h3>
            <p class="text-xs">Select an event <span class="dot"></span> from above</p>
        </div>

        <div v-if="selectedEventData" class="transform-all opacity-0 animate-moveUp">
            <a href="#" @click="$store.commit('selectProject', null)" style="position: absolute; top: -.87rem; right: -.8rem; z-index:10">
                <ion-icon name="close" class="bg-yellow-400 text-black border-black border rounded-full w-5 h-5 p-0.5 hover:bg-black hover:text-white"></ion-icon>
            </a>
            <router-link :to="{ name: 'Event', params: { id: selectedEventData.uid }}" class="bottom-card flex border border-black bg-white group divide-x divide-black mb-10 shadow-lg">
                <div class="w-36 lg:flex-shrink-0 bg-yellow-400 overflow-hidden">
                    <img :src="selectedEventData.data.featured_image.url" class="object-cover h-full w-full group-hover:scale-110 transition-all duration-500" />
                    </div>
                    <div class="w-full flex flex-col divide-y divide-black justify-between">
                    <div class="px-4 py-3 flex flex-col justify-center h-full">
                        <p class="text-xs block uppercase tracking-wide text-gray-400">Event</p>
                        <h4 class="font-serif text-xl font-bold italic">
                        {{ selectedEventData.data.title[0].text }}
                        </h4>
                    </div>
                    <div class="flex divide-x divide-black justify-between">
                        <div class="px-4 py-3 text-sm w-full">
                        {{ formattedDate(selectedEventData) }}
                        </div>
                        <div class="flex relative overflow-hidden h-full flex-shrink-0 px-4 py-3 self-center">
                        <nag-icon icon-name="arrow-forward"/>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SelectedIndicator',
    computed: {
        selectedProject () {
            return this.$store.state.selectedProject
        },
        projects () {
            return this.$store.state.projects
        },
        selectedEvent () {
            return this.$store.state.selectedEvent
        },
        events () {
            return this.$store.state.events
        },
        selectedProjectData () {
            return this.projects.find(project => project.id == this.selectedProject)
        },
        selectedEventData () {
            return this.events.find(event => event.id == this.selectedEvent)
        }
    },
    methods: {
        formattedDate (event) {
            const formattedDate = new Date(event.data.event_date + 'PST').toLocaleDateString("en-ca", this.dateOptions)
            return formattedDate != 'Invalid Date' ? formattedDate : null
        }
    }
}
</script>

<style>
    /* .icon:before {
        content: "";
        background: var(--n-yellow);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1rem;
        transform:scale(0);
        position: absolute;
        transition: transform .35s;
        transition-timing-function: ease-in-out;
        z-index: 1;
    }

    a:hover .icon:before {
        transform:scale(4);
    } */

    .v-enter-active,
    .v-leave-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }

    .fade3-enter-active, .fade3-leave-active {
        transition: opacity .25s ease-in-out;
    }
    .fade3-enter, .fade3-leave-to {
        opacity: 0;
    }

    .fade4-enter-active, .fade4-leave-active {
        transition: opacity 1s ease-in-out;
    }
    .fade4-enter, .fade4-leave-to {
        opacity: 0;
    }

    .gradient-bg {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 76%);
    }
    .dot {
        width: 1rem;
        height: 1rem;
        display: inline-block;
        border-radius: 1rem;
        vertical-align: middle;
        margin: .4rem;
        box-shadow: 0px 0px 0px .3rem rgb(250 204 21 / 0.4);
        background: #facc15;
    }

    .bottom-card {
        height: 12rem;
        width: 24rem;
    }
</style>