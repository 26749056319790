<template>

  <div id="wrap" class="overflow-hidden">

    <Artwork @current-pan="checkPan" />

    <div class="flex justify-center">
      <SelectedIndicator />
    </div>

    <div id="dev" v-if="showDev">
        <div>mouse x: {{ Math.round(dev.mouse_pos.x) }}</div>
        <div>mouse y: {{ Math.round(dev.mouse_pos.y) }}</div>
    </div>

  </div>


</template>

<script>
import Artwork from '@/components/Artwork.vue'
import SelectedIndicator from '@/components/SelectedIndicator.vue'

export default {
	name: "Home",
	components: { Artwork, SelectedIndicator },

	data () {
		return {
			
			dev: {
				mouse_pos: {},
				curr_pan: {}
			},

		}
	},
	computed: {
		showDev () {
			return this.$route.query.dev
		}
		
	},
	async mounted(){
		this.$emit('hideMenu')
		this.$store.commit('selectProject', null)
		this.$store.commit('selectEvent', null)
		this.initDevMode()
	},
  
	methods: {
    // Utilities
		initDevMode(){
			const svg = document.getElementById('artsvg');
			const pt = svg.createSVGPoint();
			function cursorPoint(evt){
				pt.x = evt.clientX; pt.y = evt.clientY;
				return pt.matrixTransform(svg.getScreenCTM().inverse());
			}
			svg.addEventListener('mousemove',evt => {
				let location = cursorPoint(evt);
				this.dev.mouse_pos = location
			},false);
		},
		checkPan(data){
		this.dev.curr_pan = data
		}
	}

}
</script>

<style scoped>

	#dev {
		position: absolute;
		top: 80px;
		padding: 1rem;
		border: 1px solid lightcoral;
		background-color: white;
		margin: 1rem;
	}

  #wrap {
    height: calc(100% - 5rem);
  }

</style>